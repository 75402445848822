// @import 'json-to-sass'; // import the json-to-sass library

// $config: json-to-sass('../config.json');

// $map: (
//   "title": map-get($config, "titles"),
//   "texts": map-get($config, "texts")
// );

@font-face {
  font-family: "open-sans";
  src: local("open-sans"),
    url(./assets/fonts/muzeum/open-sans.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "open-sans-bold";
  src: local("open-sans-bold"),
    url(./assets/fonts/muzeum/open-sans-bold.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "segoe";
  src: local("segoe"), url(./assets/fonts/cc/Segoe-UI.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "segoe-bold";
  src: local("segoe-bold"),
    url(./assets/fonts/cc/SegoeUIBlack.ttf) format("truetype");
  font-display: swap;
}

@supports (-webkit-touch-callout: none) {
  .input-field {
    width: 57px;
    height: 113px;
    margin: 0 4px;
    font-size: 42px;
  }
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  font-family: "Georgia-regular";

  //Disable text selection highlight
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE */
  user-select: none; /* Standard syntax */
}

.main-image {
  position: absolute;
  height: 0;
  top: 0px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  z-index: -10;
}

.logo {
  width: 226px;
  height: 48px;
  margin-top: 10px;
}

.logo-holder {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.dashboard-img {
  opacity: 80%;
  filter: grayscale(60%);
  /* height: 50vh; */
  margin-bottom: 50px;
  max-width: 350px;
  cursor: pointer;
}

.dashboard-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100vw;
  // height: 100vh;
  // overflow-y: hidden;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 40px;
  z-index: 10;
}

.choose-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
  margin-bottom: 139px;
  button {
    height: 48px;
    width: 174px;
    border-radius: 0px;
    padding: 11px 32px 11px 32px;
  }
  .back {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 32px;
    gap: 10px;
    // background: #ffffff;
    // border: 1px solid #5a513f;
    background-color: #00001e;
    border: 1px solid #00d5ff;
    text-transform: uppercase;
    // font-family: "open-sans";
    font-family: "segoe-bold";
    font-weight: 600;
    // color: #5a513f;
    color: #00d5ff;
  }
  .choose {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 32px;
    gap: 10px;
    // background: #5a513f;
    // border: 1px solid #5a513f;
    background-color: #00d5ff;
    border: 1px solid #00d5ff;
    text-transform: uppercase;
    // font-family: "open-sans";
    font-family: "segoe-bold";
    font-weight: 600;
    color: white;
  }
}

.not-owned-card-image {
  height: 449px;
  width: 449px;
  border-radius: 0px;
}

.dash-buttons {
  background-color: transparent;
  /* color: rgba(255, 255, 255, 0.8); */
  color: black;
  cursor: pointer;
  border: 3px solid rgb(82 79 74 / 80%);
  border-radius: 36px;
  font-size: 14px;
  font-family: "montserrat";
  font-weight: 700;
  margin-bottom: 48px;
  padding: 10px;
}

.main-title {
  margin: 0;
  /* color: rgb(211, 198, 125); */
  text-align: center;
}

.station-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  //height: 100vh;
}

.station-number {
  font-size: 15px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 60vh; */
}

.station-container .station-number .exp-image {
  width: 449px;
  height: 449px;
}
.error-inner-texts {
  height: unset !important;
}
.error-inner-texts .mintpage-button {
  position: fixed;
  padding: 10px;
}

.success-mint {
  color: #50ae55;
}

.error-div {
  margin-top: 160px;
}

.error-mint {
  color: #ff3333;
}

.mint-message {
  font-family: "open-sans";
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  color: #5a513f;
  max-width: 608px;
  margin: 0;
}

.navback-icon {
  position: absolute;
  left: 5px;
  top: 5px;
  padding: 40px;
}

.pin-form {
  //margin-top: 40px;
  min-height: 250px;
}

.pin-input {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.enter-pin {
  font-family: "open-sans";
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  color: #5a513f;
  margin-top: 0px;
  margin-bottom: 40px;
}

.goback-icon {
  position: absolute;
  top: 34px;
  left: 8.33%;
  //left: 185.33px;
  width: 33px;
  height: 33px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.inputs {
  max-width: 276px;
  display: flex;
  margin-bottom: 8px;
  input {
    font-family: "open-sans";
    font-size: 16px;
  }
}
.inputs div {
  margin: 0 5px;
}
.input-field {
  height: 80px;
  width: 48px;
  margin: 0 4px;
  padding: 0;
  /* width: 100%;
  height: 113px; */
  font-size: 42px;
  border: none;
  background: linear-gradient(0deg, #f6f6f7, #f6f6f7),
    linear-gradient(0deg, rgba(90, 81, 63, 0.3), rgba(90, 81, 63, 0.3));
  border: 1px solid #5a513f4d;
  color: #5a513f;
  text-align: center;
  font-family: Georgia, "Times New Roman", Times, serif;
  border-radius: 0%;
  box-shadow: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  text-overflow: ellipsis;
}

.input-field:focus {
  outline: none;
}

.incorrect {
  background: #ff3333;
  border: 1px solid red;
  padding: 0;
}

.incorrect-pin {
  background-color: rgb(182 2 2 / 50%);
  border: 1px solid red;
  background-image: url("./assets/images/erroor.png") !important;
  background-repeat: no-repeat !important;
  padding: 0;
}

.incorrect-pin::after {
  content: "helytelen pin" !important;
  display: inline-block;
  width: 100px;
  height: 100px;
}

.warning-text {
  // margin-top: 8px;
  color: #ff3333;
  font-size: 26px;
  font-family: "open-sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.navigationBUtton {
  text-align: center;
  margin-top: 30px;
}

.mint-feedback-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mint-feedback-inner-texts {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 77vh; */
}

.mintpage-button-holder {
  display: flex;
  justify-content: center;
  gap: 20px;
  /* margin-bottom: 40px; */
  z-index: 10;
}

.mintpage-button {
  cursor: pointer;
  border-radius: 28px;
  border: 3px solid rgba(176, 147, 91, 0.8);
  background-color: transparent;
  color: white;
  font-size: 14px;
  font-family: "montserrat";
  margin-bottom: 48px;
}

.experience-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.experience {
  font-family: "segoe-bold";
  font-weight: 800;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: center;
  // color: #ba5b2d;
  font-style: normal;
  font-size: 32px;
  text-transform: uppercase;
  margin-top: 40px;
  margin-bottom: 16px;
}

.experience-text {
  font-family: "segoe";
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  color: #5a513f;
  margin-top: 0;
  margin-bottom: 40px;
  margin-left: 30px;
  margin-right: 30px;
}
.exp-name {
  // font-family: "open-sans-bold";
  font-family: "segoe-bold";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
  // color: #ba5b2d;
  text-align: start;
  margin-bottom: 48px;
  text-align: center;
}
.logout-btn img {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  height: 30px;
  width: 33.33333206176758px;
  // opacity: 0%;
}
.experience-name {
  /* color: #cbac6e; */
  font-size: 26px;
  line-height: 36px;
  text-shadow: 1px 1px #000000;
  margin-top: 0;
  margin-bottom: 24px;
}

.home-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 32px;
  gap: 10px;
  width: 229px;
  height: 48px;
  background: #5a513f;
  //background-color: #00d5ff;
  border: none;
  text-transform: uppercase;
  color: white;
  //font-family: "open-sans";
  font-family: "segoe-bold";
  font-weight: 900;
  //margin-bottom: 139px;
}

#cimer {
  margin-bottom: 100px;
}

.cimer-texts {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.splash-screen {
  min-height: 100vh;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.splash-screen-svgs {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 15px;
  margin: 0 auto;
  width: 150px;
  margin-bottom: 50px;
}

/* ///////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////// */

.splash-house {
  width: 125px;
}

.splash-logo {
  width: 150px;
}

@media (max-width: 800px) {
  .logo {
    width: 173px;
    height: 36px;
  }

  .dashboard-img {
    width: 258px;
    margin-bottom: 41px;
  }

  .buttons {
    gap: 16px;
  }

  .dash-buttons {
    border-radius: 26px;
    font-size: 12px;
    width: 126px;
    height: 50px;
    margin-bottom: 48px;
  }
  /* 
  .mint-message {
    font-size: 20px;
    margin-top: 8px;
    margin: 0 20px;
    margin-bottom: 24px;
  } */

  .mintpage-button {
    font-size: 12px;
    font-weight: 400;
    width: 126px;
    height: 50px;
  }

  .enter-pin {
    margin-bottom: 16px;
    font-size: 20px;
  }

  .warning-text {
    margin-top: 16px;
    font-size: 20px;
  }

  .navigationBUtton {
    text-align: center;
    margin-top: 30px;
  }

  .experience {
    font-size: 22px;
    line-height: 25px;
  }

  .experience-name {
    font-size: 20px;
    line-height: 22px;
  }
}

.experience-holder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 24px;
  row-gap: 96px;
}

@media (max-width: 700px) {
  .station-number {
    margin-bottom: 80px;
  }

  .experience-holder {
    flex-direction: column;
  }
}

.experience-holder div {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 291px;
  width: 291px;
  margin-bottom: 30px;
}

.experience-holder div img {
  height: 291px;
  width: 291px;
  border-radius: 0px;
  cursor: pointer;
}

.splash-button {
  padding: 10px;
  border-radius: 50px;
  background: #787167;
  border: none;
  color: white;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
}

.footer {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  font-family: 'segoe';
  font-size: 14px;
  margin-top: 80px;
  margin-bottom: 40px;
}

.ccLink {
  color:rgb(186, 91, 45);
  text-decoration: none;
  font-family: 'segoe-bold';
}

.title-home {
  font-size: 32px;
  font-weight: 900;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: center;
  // color: #ba5b2d;
  margin-top: 40px;
  margin-bottom: 16px;
  // font-family: "open-sans-bold";
  font-family: "segoe-bold";
  text-transform: uppercase;
}

.feedback-msg {
  margin-top: 40px;
}

.feedback-msg .error-mint,
.feedback-msg .success-mint {
  margin-bottom: 16px;
  margin-top: 0;
  font-family: "open-sans-bold";
  font-size: 32px;
  font-weight: 800;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: center;
}

.feedback-msg .minted {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-top: 40px;
}

.keyboard-wrapper input {
  height: 90px;
  width: 245px;
  border-radius: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  //background-image: url("./assets/images/pin2.png");
  background-image: url("./assets/images/pin2.png");
  background-repeat: no-repeat;
  font-size: 42px;
  color: #5a513f;
  // font-family: "open-sans";
  font-family: "segoe";
  color: black;
  font-weight: 700;
  letter-spacing: 57px;
  padding-left: 23px;
  font-size: 16px;
  margin-left: 12px;
}

.ui-keyboard {
  display: flex;
  justify-content: center;
  padding: 2px;
  min-width: 532px;
  z-index: 1000000;
  //background: #ffffff;
  //background-color: #00001e;
  margin-top: 80px;
  box-sizing: border-box;
}

.loader {
  position: absolute;
  height: fit-content;
  top: 40%;
  left: 45%;
}

@media (max-width: 768px) {
  .keyboard-wrapper input {
    margin-left: 15px;
  }
}

@media (max-width: 425px) {
  .title-home {
    font-size: 24px;
  }
  .ui-keyboard {
    margin-left: -50px;
  }

  .keyboard-wrapper input {
    margin-left: 15px;
  }

  .station-number {
    margin-bottom: 80px;
  }

  .experience-holder {
    flex-direction: column;
  }

  .choose-buttons button {
    width: 154px;
  }

  .card .not-owned-card-image {
    width: 335px;
    height: 335px;
  }

  .station-container .station-number .exp-image {
    width: 335px;
    height: 335px;
  }

  .loader {
    left: 35%;
  }

  .mint-message {
    max-width: 335px;
  }
}

@media (max-width: 375px) {
  .ui-keyboard {
    margin-left: -60px;
  }

  .keyboard-wrapper input {
    margin-left: 25px;
  }
}

.ui-keyboard-input {
  //box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  //padding: 4px 11px;
  color: #000000d9;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}

.ui-keyboard-input:focus {
  border-color: var(--ant-primary-color-hover) !important;
  box-shadow: 0 0 0 2px var(--ant-primary-color-outline) !important;
  border-right-width: 1px !important;
  outline: 0;
}

button.ui-keyboard-button {
  margin: 4px;

  min-width: 40px;
  min-height: 40px;
  box-sizing: border-box;

  border: none;
  outline: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

button.ui-keyboard-button:hover,
button.ui-keyboard-button:focus {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

button.ui-keyboard-button > span.ui-keyboard-text {
  font-weight: bolder;
}

.ui-keyboard button {
  width: 100px;
  height: 60px;
  border-radius: 5px;
  font-family: "open-sans";
  color: #5a513f;
}

.modal-holder {
  position: absolute;
  background: rgba(0, 0, 0, 0.21);
  width: 100vw;
  height: 100vh;
}

.loginpage {
  height: 100%;
  overflow-y: hidden;
}

.addressError {
  text-align: center;
  font-size: 25px;
  margin-top: 150px;
  font-family: 'open-sans';
  font-weight: 600;
  color: #5a513f;
}

.language-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
  margin-bottom: 32px;
  height: 100%;
  overflow-x: hidden;
}


.page-title {
  // color: #ba5b2d;
  width: 335px;
  height: 96px;
  // font-family: "open-sans-bold";
  font-family: "segoe-bold";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 32px;
}

.language-buttons-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.language-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 32px 8px 8px;
  gap: 4px;
  width: 335px;
  height: 48px;
  // background: #5a513f;
  background-color: #00d5ff;
  border: none;
  // font-family: "open-sans";
  font-family: "segoe-bold";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  // text-transform: uppercase;
  flex: none;
  order: 1;
  flex-grow: 0;
  cursor: pointer;
}
