.video-element {
  height: 100vh;
  width: 100vw;
  min-height: 100%;
  min-width: 100%;
  margin: 0 auto;
}

.qr-close-btn {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 45px;
  right: 22px;
  z-index: 10;
}

.qr-close-btn:hover {
  cursor: pointer;
}

.qr-close-btn img {
  width: 40px;
  height: 40px;
}

@media screen and (max-width: 425px) {
  .qr-close-btn {
    top: 40px;
    right: 40px;
  }
}

.scan-instructions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "open-sans-bold";
  font-style: normal;
  font-weight: 900px;
  color: rgb(90, 81, 63);
  margin-top: 30px;
  font-size: 24px;
}

.up-arrow {
  width: 67px;
  height: 67px;
}

.scan-region-highlight-svg {
  stroke: #fff !important;
}

.scanner-holder {
  width: 100vw !important;
  height: 100vh !important;
}

.scanner-holder .qr-reader section div {
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  margin: auto !important;
  border: none !important;
  box-shadow: white 0px 0px 0px 5px inset !important;
  width: 400px !important;
  height: 400px !important;
}

.scanner-holder .qr-reader section {
  position: static !important;
  padding: 0 !important;
  margin-top: 30x;
}

.scanner-holder .qr-reader section video {
  position: relative !important;
  margin: 0 auto !important;
  width: 80vw !important;
  height: 80vh !important;
}

@media screen and (max-width: 800px) {
  .scanner-holder .qr-reader section div {
    width: 200px !important;
    height: 200px !important;
  }
}

@media screen and (max-width: 500px) {
  .scanner-holder .qr-reader section video {
    width: 100vw !important;
    height: 100vh !important;
  }
}

@media screen and (min-width: 500px) {
  .scanner-holder .qr-reader section video {
    transform: rotateY(180deg) !important;
  }
}
